exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-low-code-tools-js": () => import("./../../../src/pages/low-code-tools.js" /* webpackChunkName: "component---src-pages-low-code-tools-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-project-showcase-js": () => import("./../../../src/pages/project-showcase.js" /* webpackChunkName: "component---src-pages-project-showcase-js" */),
  "component---src-pages-services-cloud-and-devops-js": () => import("./../../../src/pages/services/cloud-and-devops.js" /* webpackChunkName: "component---src-pages-services-cloud-and-devops-js" */),
  "component---src-pages-services-cloud-cost-optimization-js": () => import("./../../../src/pages/services/cloud-cost-optimization.js" /* webpackChunkName: "component---src-pages-services-cloud-cost-optimization-js" */),
  "component---src-pages-services-hire-offshore-developers-js": () => import("./../../../src/pages/services/hire-offshore-developers.js" /* webpackChunkName: "component---src-pages-services-hire-offshore-developers-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-resource-augmentation-js": () => import("./../../../src/pages/services/resource-augmentation.js" /* webpackChunkName: "component---src-pages-services-resource-augmentation-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-what-we-do-cloud-migrations-and-cost-savings-js": () => import("./../../../src/pages/what-we-do/cloud-migrations-and-cost-savings.js" /* webpackChunkName: "component---src-pages-what-we-do-cloud-migrations-and-cost-savings-js" */),
  "component---src-pages-what-we-do-resource-augmentation-js": () => import("./../../../src/pages/what-we-do/resource-augmentation.js" /* webpackChunkName: "component---src-pages-what-we-do-resource-augmentation-js" */),
  "component---src-pages-what-we-do-software-projects-js": () => import("./../../../src/pages/what-we-do/software-projects.js" /* webpackChunkName: "component---src-pages-what-we-do-software-projects-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

